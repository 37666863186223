var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data
    ? _c(
        "div",
        {
          staticClass: "flex a-center gap4 user-details-container",
          class: { pointer: _vm.data.user },
        },
        [
          _vm.data.name
            ? _c("user-avatar", {
                staticClass: "md-none",
                attrs: {
                  user: _vm.data.user || _vm.data,
                  "img-class": "table-client__photo",
                },
                nativeOn: {
                  click: function ($event) {
                    $event.stopPropagation()
                    _vm.data.user ? _vm.clientDetails(_vm.data.user) : ""
                  },
                },
              })
            : _vm._e(),
          !_vm.data.isOnlyImg
            ? _c("section", { staticClass: "flex col a-start gap4" }, [
                _vm.data.name
                  ? _c("button", {
                      staticClass: "same-line size-16 opacity-9",
                      domProps: { textContent: _vm._s(_vm.data.name) },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          _vm.data.user || _vm.data.user_id
                            ? _vm.clientDetails(
                                _vm.data.user || _vm.data.user_id
                              )
                            : ""
                        },
                      },
                    })
                  : _vm._e(),
                _vm.data.phone
                  ? _c("button", {
                      staticClass: "size-14 opacity-6 md-none",
                      domProps: { textContent: _vm._s(_vm.data.phone) },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.openDialer.apply(null, arguments)
                        },
                      },
                    })
                  : _vm._e(),
                _vm.data.phone
                  ? _c("a", {
                      staticClass: "size-14 opacity-6 d-md-none",
                      attrs: { href: "tel:" + _vm.data.phone },
                      domProps: { textContent: _vm._s(_vm.data.phone) },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                        },
                      },
                    })
                  : _vm._e(),
              ])
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }